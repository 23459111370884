import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { CREATE_REQUEST, CREATE_ROUTES_FORECAST, UPDATE_REQUEST } from '../../mutations'
import { GET_LABELS, GET_USERS, GET_STATIONS } from '../../queries';
import { authenticationService } from '../../Utilities/authenticationService'
import { DatePickerWithTimeInForm } from '../../Utilities/datepickerWithTimeInForm';
import {
    Tabs,
    message,
    Select,
    Form,
    Input,
    Button,
    Drawer,
    InputNumber,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

const NewRequestDrawer = ({ 
    visible, 
    setVisible,
    refetch
}) => {
    const currentUser = authenticationService.currentUserValue

    const { data: stationsData, loading: loadingStations } = useQuery(GET_STATIONS);
    const [autoUpdated, setAutoUpdated] = useState(false);
    const { data: usersData } = useQuery(GET_USERS);
    const [labelId, setLabelId] = useState();
    const [fromId, setFromId] = useState(currentUser?.station_id);
    const [toId, setToId] = useState();
    const [userId, setUserId] = useState();
    const [parkedLoad, setParkedLoad] = useState();
    const [remarks, setRemarks] = useState();
    const [vehicleId, setVehicleId] = useState();
    const [price, setPrice] = useState();

    const [getLabelsData, { data: labelsData }] = useLazyQuery(GET_LABELS);

    const [updateRequest] = useMutation(UPDATE_REQUEST, {
        onCompleted: (data) => {
            if (data.updateRequest.request?.overlaps?.length > 0) {
                message.error('選択したトラックは、リクエストされた時間帯にすでにスケジュールされています。リクエストを承認できません。');
            } else {
                message.success(autoUpdated ? 'リクエストを自動承認しました。' : 'リクエストが更新されました')
            }
            setAutoUpdated(false)
            refetch();
        },
        onError: (error) => { console.log(error); }
    });

    const [createRequest] = useMutation(CREATE_REQUEST, {
        onCompleted: (data) => {
            message.success('リクエストを送信しました。');
            setVisible(false);
            if(vehicleId && parseInt(toId) === currentUser.station_id && parseInt(userId) === currentUser.id) {
                setAutoUpdated(true);
                updateRequest({ variables: { input: { id: data.createRequest.request.id, status: 'accepted' }}})
            } else {
                refetch();
            }
            form.resetFields();
        },
        onError: (error) => {
            message.error('リクエストを送信できませんでした。');
        }
    });

    const [createRoutesForecast] = useMutation(CREATE_ROUTES_FORECAST, {
        onCompleted: (data) => {
            createRequest({ variables: { input: {
                opsDate: data.createRoutesForecast.routesForecast.opsDate,
                fromId: fromId,
                forecastId: data.createRoutesForecast.routesForecast.id,
                userId,
                remarks,
                toId,
                status: 'sent',
                vehicleId: vehicleId ? vehicleId : null,
                postingId: null,
                price,
            }}});
        },
        onError: (error) => {
            message.error('リクエストを送信できませんでした。');
        }
    });

    const onNewRequest = ({ remarks, fromId, toId, userId, departureTime, arrivalTime, maxTruckCapacity, driverLicenseClass, vehicleType, vehicleId, price, employeeId, employeeName }) => {
        setVehicleId(vehicleId);
        setUserId(userId);
        setRemarks(remarks);
        setPrice(price);
        createRoutesForecast({ variables: { input: {
            opsDate: moment(departureTime).format('YYYY-MM-DD'),
            stationId: fromId,
            vehicleStationId: toId,
            vehicleId,
            maxTruckCapacity,
            driverLicenseClass,
            vehicleType,
            remarks,
            labelId,
            parkedLoad,
            departureTime,
            arrivalTime,
            pending: true,
            employeeId: employeeId,
            employeeName: employeeName
        }}});
    };

    const [form] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({});
    }, []);

    useEffect(() => {
        if (fromId) {
            getLabelsData({ variables: { stationId: fromId || currentUser.station_id } })
        }
    }, [getLabelsData, visible, currentUser, fromId]);

    const tailFormItemLayout = {
        wrapperCol: {
            sm: {
                span: 16,
                offset: 9,
            }
        }
    };
    const NewRequestForm = () => {
        return (
        <Form
            name='request'
            form={form}
            onFinish={onNewRequest}
            colon={false}
            layout="vertical"
        >
            <Form.Item
                label="依頼元営業所"
                name='fromId'
                initialValue={String(currentUser.station_id)}
                rules={[{ required: true, message: '営業所を入力してください。' }]}
            >
                <Select
                    showSearch
                    disabled={!currentUser?.admin}
                    placeholder="選択してください"
                    style={{ width: 200 }}
                    onChange={(e) => setFromId(e)}
                >
                    {
                        !loadingStations && stationsData.stations.edges.map(
                            station => (
                                <Select.Option key={station.node.id} value={station.node.id}>
                                    {station.node.officialName}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="依頼先営業所"
                name='toId'
                rules={[{ required: true, message: '営業所を入力してください。' }]}
            >
                <Select
                    showSearch
                    placeholder="選択してください"
                    style={{ width: 200 }}
                    onChange={(e) => setToId(e)}
                >
                    {
                        !loadingStations && stationsData.stations.edges.map(
                            station => (
                                <Select.Option key={station.node.id} value={station.node.id}>
                                    {station.node.officialName}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="承認者"
                name='userId'
                rules={[{ required: true, message: '送り先を入力してください。' }]}
            >
                { usersData && <Select
                    showSearch
                    placeholder="選択してください"
                    style={{ width: 200 }}
                    filterOption={(input,option)=> option.children.join('').includes(input)}
                >
                    {
                        usersData.users.edges.filter(user => user.node.station?.id === toId).map(
                            user => {
                                return(
                                <Select.Option key={user.node.id} value={user.node.id}>{user.node.lastName} {user.node.firstName}</Select.Option>
                            )}
                        )
                    }
                </Select> }
            </Form.Item>
            <Form.Item
                label="ルート"
                name='label'
                rules={[{ required: true, message: 'ルートを入力してください。' }]}
            >
                <Select
                    placeholder="選択してください"
                    showSearch
                    onChange={setLabelId}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        labelsData && labelsData.mostFrequentlyUsedLabels.map(
                            label => (
                                label.enabled &&
                                    <Select.Option key={label.id} value={label.id}>{label.label.join('|')}</Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="車両番号"
                name="vehicleId"
                rules={[{ required: false, message: '車両番号を入力してください。' }]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="乗務員ID"
                name="employeeId"
                rules={[{ required: false, message: '正しい乗務員IDを入力してください。' }]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="乗務員名"
                name="employeeName"
                rules={[{ required: false, message: '乗務員名を入力してください。' }]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="運賃"
                name="price"
                rules={[{ required: false, message: '運賃を入力してください。' }]}
            >
                <InputNumber min={0}/>
            </Form.Item>
            <DatePickerWithTimeInForm label='出発時刻' name='departureTime' />
            <DatePickerWithTimeInForm label='到着時刻' name='arrivalTime' />
            <Form.Item
                label="荷有り"
                name='parkedLoad'
                rules={[{ required: true, message: '荷物の有無を入力してください。' }]}
            >
                <Select 
                    placeholder="選択してください" onChange={setParkedLoad}>
                    {
                        ["空", "荷あり"].map(parkedLoadType => (
                                <Select.Option key={parkedLoadType} value={parkedLoadType}>
                                    {parkedLoadType}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>

            <Form.Item
                label="備考欄"
                name='remarks'
                rules={[{ required: false }]}
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item shouldUpdate {...tailFormItemLayout}>
                { () => (
                    <Button
                        disabled={ !form.getFieldValue('toId') ||
                                    !form.getFieldValue('userId') ||
                                    !form.getFieldValue('label') ||
                                    !form.getFieldValue('departureTime') ||
                                    !form.getFieldValue('arrivalTime') ||
                                    !form.getFieldValue('parkedLoad') ||
                                    form.getFieldsError().filter(({ errors }) => errors.length).length}
                        type="primary"
                        htmlType='submit'
                    >
                        送信する
                    </Button>
                )}
            </Form.Item>
        </Form>
    )}

    return <>
        <Drawer
            width={400}
            open={visible}
            footer={null}
            closable={false}
        >
            <div
                style={{ 
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    width: '100%',
                    marginBottom: '-20px'
                }}
            >
                <Button
                    type='text'
                    onClick={() => setVisible(false)}
                    style={{ zIndex: 1 }}
                >    
                    <CloseOutlined />
                </Button>
            </div>
            
            <Tabs items={[
                {
                    label: '新規リクエスト',
                    key: 'new',
                    children: (<NewRequestForm />)
                }
            ]}>
            </Tabs>
        </Drawer>
    </>
};

export default NewRequestDrawer;
