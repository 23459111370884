import React, { useEffect, useState } from 'react';
import { Dropdown, Layout, Menu, Row, Col, Typography, Avatar } from 'antd';
import propTypes from 'prop-types';
import './authorizedlayout.less';
import { NavLink, withRouter } from 'react-router-dom';
import { PATHS }  from '../../constants';
import {
    ToolOutlined,
    CarOutlined,
    CalendarOutlined,
    LayoutOutlined,
    NotificationOutlined,
    ProfileOutlined,
    SettingOutlined,
    LogoutOutlined,
    DownOutlined,
    PlaySquareOutlined,
    ShoppingOutlined,
    DeleteOutlined,
    UserOutlined
} from '@ant-design/icons';
import { AUTH_CHECK } from '../../queries';
import { useQuery } from '@apollo/client';
import history from '../../Utilities/history';
import { authenticationService } from '../../Utilities/authenticationService';

const { Header, Content, Sider } = Layout;

const AuthorizedLayout = ({ location, children, disableBorder, onLogout, title }) => {
    const [currentPath, setCurrentPath] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const currentUser = authenticationService.currentUserValue

    useQuery(AUTH_CHECK, {
        onError: () => {
            authenticationService.logout();
            history.push({
                pathname: PATHS.login,
                state: { from: { location: location.pathname } }
            });
        }
    });

    useEffect(() => {
        setCurrentPath(location.pathname.split('/')[1]);
    }, [location]);

    const UserMenu = <Menu key="user-panel" className="user-panel">
        <Menu.Item icon={<SettingOutlined />} key="settings"><NavLink to={PATHS.userSettings}>アカウント</NavLink></Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} key='logout' onClick={onLogout}>ログアウト</Menu.Item>
    </Menu>;

    const sidemMenuItems = [
        
        {
            key: 'truckPosting',
            label: <NavLink to={PATHS.truckPosting.split('/')[1]}>空車掲示板</NavLink>,
            icon: <CarOutlined />
        },
        {
            key: 'freightPosting',
            label: <NavLink to={PATHS.freightPosting.split('/')[1]}>荷物掲示板</NavLink>,
            icon: <ShoppingOutlined />
        },
        {
            type: 'divider'
        },
        {
            key: 'truckSchedule',
            label: <NavLink to={PATHS.truckSchedule}>運行スケジュール</NavLink>,
            icon: <CalendarOutlined />
        },
        ((!currentUser?.contractor) || (currentUser?.contractor && currentUser?.admin)) && {
            key: 'labeling',
            label: <NavLink to={PATHS.labeling}>ラベルツール</NavLink>,
            icon: <ToolOutlined />
        },
        {
            key: 'requestsList',
            label: <NavLink to={`/${PATHS.requestsList.split('/')[1]}`}>リクエスト一覧</NavLink>,
            icon: <NotificationOutlined />
        },
        {
            key: 'simulation',
            label: <NavLink to={PATHS.simulation}>シミュレーション</NavLink>,
            icon: <CalendarOutlined />
        },
        {
            key: 'routeManagement',
            label: <NavLink to={PATHS.routeManagement}>ルート管理</NavLink>,
            icon: <ProfileOutlined />
        },
        ((!currentUser?.contractor) || (currentUser?.contractor && currentUser?.admin)) && {
            key: 'dashboard',
            label: <NavLink to={PATHS.dashboard}>ダッシュボード</NavLink>,
            icon: <LayoutOutlined />
        },
        {
            key: 'archived',
            label: <NavLink to={PATHS.archived}>削除済みルート</NavLink>,
            icon: <DeleteOutlined />
        },
        {
            key: 'tutorial',
            label: <NavLink to={PATHS.tutorial}>使い方</NavLink>,
            icon: <PlaySquareOutlined />
        }
    ];
    const Sidebar = <Sider collapsible onCollapse={value => setCollapsed(value)} className="site-layout-background site-layout-sidebar">
        <Row type="flex" justify="flex-start">
            <Col>
                <div className="logo" style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{ display: 'inline-block', verticalAlign: 'middle' }} src={require('../../static/DentistLogo.png')} className='app-logo' alt='daiseilogo' />
                </div>
            </Col>
        </Row>
        <Menu
            mode="inline"
            defaultOpenKeys={['data']}
            style={{ height: '100%', borderRight: 0 }}
            selectedKeys={currentPath}
            items={sidemMenuItems}
        />
    </Sider>;

    const Topbar = <Header className="main-header">
        <Typography.Title level={4} style={{float: "left", marginTop: "0.8em", marginLeft: "0.8em"}}>{title}</Typography.Title>
        <Dropdown overlay={UserMenu} trigger={['click']}>
            <div style={{ marginRight: '24px' }}>
                <Avatar size='small' icon={<UserOutlined />} style={{ marginRight: '0.5rem'}} />
                { JSON.parse(localStorage.currentUser).last_name } { JSON.parse(localStorage.currentUser).first_name } <DownOutlined />
            </div>
        </Dropdown>
    </Header>;

    return <Layout>
        <Layout>
            { Sidebar }
            <Layout style={{marginLeft: collapsed ? 80 : 200}}>
                { Topbar }
                <Content
                    className={ disableBorder ? '' : 'site-layout-background' }
                    style={{
                        padding: '4px 0px 24px 0px',
                        margin: 0,
                        minHeight: 280,
                        marginTop: 64
                    }}
                >
                    { children }
                </Content>
            </Layout>
        </Layout>
    </Layout>;
};

AuthorizedLayout.propTypes = {
    children: propTypes.element,
    onLogout: propTypes.func,
    location: propTypes.object,
    disableBorder: propTypes.bool
};

export default withRouter(AuthorizedLayout);
