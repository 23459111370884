import React, { useEffect, useMemo, useState } from 'react';
import {
    Typography,
    Table,
    Spin,
    Button,
    Empty,
    Tag, Avatar
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import TruckSearchRequestDrawer from '../TruckSearch/TruckSearchRequestDrawer';
import { GET_STATIONS, GET_POSTED_TRUCKS } from '../../../queries';
import { DELETE_POSTING } from '../../../mutations';
import momentify from '../../../Utilities/momentify';
import moment from 'moment'
import 'moment/locale/ja';
import TraboxDrawer from './TraboxDrawer';
import { authenticationService } from '../../../Utilities/authenticationService';

const PostingTable = ({
    station,
    dateRange,
    refresh,
    setRefresh,
    history,
    searchText,
    onlyExpired = false
}) => {
    const [originalPostedTrucks, setOriginalPostedTrucks] = useState([]);
    const { data: stationsData } = useQuery(GET_STATIONS);
    const [showTraboxDrawer, setShowTraboxDrawer] = useState(false);
    const [detail, setDetail] = useState();

    const currentUser = authenticationService.currentUserValue
    
    const { data, refetch, error, startPolling } = useQuery(GET_POSTED_TRUCKS, {
        variables: {
            search:  `end_date ${onlyExpired ? "<=" : ">="} '` + moment().format('YYYY-MM-DD') + "'" // onlyExpired shows postings that have already ended
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const trucks = data.postings.edges.filter(e => e.node.status === 'available').map(posting => ({
                key: posting.node.id,
                id: posting.node.id,
                opsDate: posting.node.opsDate,
                startDate: moment(posting.node.startDate).format('YYYY-MM-DD HH:mm'),
                endDate: moment(posting.node.endDate).format('YYYY-MM-DD HH:mm'),
                station: posting.node.station.officialName,
                stationName: posting.node.station.officialName,
                stationId: posting.node.station.id,
                companyName: posting.node.user.company?.companyName,
                vehicleId: posting.node.truck.vehicleId,
                vehicleType: posting.node.truck.vehicleType,
                maxTruckCapacity: posting.node.truck.maxTruckCapacity,
                driverLicenseClass: posting.node.truck.driverLicenseClass,
                postedBy: `${posting.node.user.lastName} ${posting.node.user.firstName}`,
                userId: posting.node.user.id,
                postedDate: posting.node.createdAt,
                vehicle: {
                    key: posting.node.truck.id,
                    id: posting.node.truck.id,
                    date: posting.node.opsDate,
                    station: posting.node.station.officialName,
                    stationId: posting.node.station.id,
                    vehicleType: posting.node.truck.vehicleType,
                    vehicleId: posting.node.truck.vehicleId,
                    maxTruckCapacity: posting.node.truck.maxTruckCapacity,
                    driverLicenseClass: posting.node.truck.driverLicenseClass
                },
                comments: posting.node.truckPostingComments.map(comment => ({
                    id: comment.id,
                    userId: comment.user.id,
                    author: <Typography.Text strong>{`${comment.user.lastName} ${comment.user.firstName}`}</Typography.Text>,
                    avatar: <Avatar>{comment.user.lastName[0]}</Avatar>,
                    content: <p key={comment.id}>{comment.commentText}</p>,
                    datetime: moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm'),
                })),
                traboxStatus: posting.node.traboxStatus,
                routesForecastId: posting.node.routesForecastId

            }))
            setOriginalPostedTrucks(trucks);
            startPolling(10000);
        }
    });

    const [deletePosting] = useMutation(DELETE_POSTING, {
        onCompleted: (data) => {
            refetch()
        },
        onError: (error) => {
            console.log(error);
        }
    })

    const handleDelete = (id) => {
        deletePosting({
            variables: {
                input: {
                    id
                }
            }
        })
    }
    useEffect(() => {
        if (refresh) {
            refetch();
            setRefresh(false);
        }
    }, [refresh, refetch, setRefresh])
    const columns = [
        {
            render: rowContent => <TruckSearchRequestDrawer disableToId={true} showExistingTab={false} key={rowContent} stationsData={stationsData} dateRange={[moment(rowContent.startDate), moment(rowContent.endDate)]} truck={rowContent.vehicle} posting={rowContent} paramStationId={rowContent.stationId} refetchPostings={refetch} buttonMode={true} traboxButton={
                <Button onClick={()=> {setShowTraboxDrawer(true); setDetail(rowContent)}}>トラボックスに送信する</Button>
            }/>,
            width: 25
        },
        {
            title: <Typography.Text strong>運行日</Typography.Text>,
            dataIndex: 'opsDate',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            width: 50
        },
        {
            title: <Typography.Text strong>出発時刻</Typography.Text>,
            dataIndex: 'startDate',
            width: 50
        },
        {
            title: <Typography.Text strong>到着時刻</Typography.Text>,
            dataIndex: 'endDate',
            width: 50
        },
        {
            title: <Typography.Text strong>営業所</Typography.Text>,
            dataIndex: 'stationName',
            width: 65
        }, 
        {
            dataIndex: 'companyName',
            title: <Typography.Text strong>会社名</Typography.Text>,
            width: 50
        },
        {
            title: <Typography.Text strong>車種名</Typography.Text>,
            dataIndex: 'vehicleType',
            sorter: (a, b) => a.vehicleType && b.vehicleType && a.vehicleType.localeCompare(b.vehicleType, 'ja'),
            width: 50
        }, {
            title: <Typography.Text strong>車両番号</Typography.Text>,
            dataIndex: 'vehicleId',
            width: 50
        }, {
            title: <Typography.Text strong>積載重量</Typography.Text>,
            dataIndex: 'maxTruckCapacity',
            sorter: (a, b) => a.maxTruckCapacity - b.maxTruckCapacity,
            width: 65
        }, {
            title: <Typography.Text strong>免許タイプ</Typography.Text>,
            dataIndex: 'driverLicenseClass',
            sorter: (a, b) => a.driverLicenseClass - b.driverLicenseClass,
            width: 50
        },
        {
            title: <Typography.Text strong>投稿者</Typography.Text>,
            dataIndex: 'postedBy',
            sorter: (a, b) => a.driverLicenseClass - b.driverLicenseClass,
            width: 50
        },
        {
            title: <Typography.Text strong>投稿日</Typography.Text>,
            dataIndex: 'postedDate',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render: rowContent => momentify(rowContent),
            width: 50
        },
        {
            render: rowContent => currentUser.id === parseInt(rowContent.userId) && <Button type='link' onClick={()=> handleDelete(rowContent.id)}>削除する</Button>,
            width: 50,
            fixed: 'right'
        },
        {
            title: <Typography.Text strong>トラボックス進捗</Typography.Text>,
            render: (rowContent, record) => {
                if (!rowContent) {
                    return <Button type='link' onClick={()=> {setShowTraboxDrawer(true); setDetail(record)}}>トラボックスに送信する</Button>
                } else if (rowContent === 'sent') {
                    return <Tag color='blue'>処理中</Tag>
                } else if (rowContent === 'success') {
                    return <Tag color='green'>投稿完了</Tag>
                } else if (rowContent === 'failed') {
                    return <Tag color='red'>投稿失敗</Tag>
                }
            },
            dataIndex: 'traboxStatus',
            width: 100,
            fixed: 'right'
        },
    ]

    const filteredPostedTrucks = useMemo(() => {
        let filteredTrucks = [...originalPostedTrucks]
        if (station.length) {
            filteredTrucks = filteredTrucks.filter(truck => station.includes(truck.stationId))
        }
        if (dateRange.length) {
            const [startDate, endDate] = dateRange;
            filteredTrucks = filteredTrucks.filter(truck => {
                return moment(startDate).isSameOrAfter(truck.startDate, 'minute') && moment(endDate).isSameOrBefore(truck.endDate, 'minute')
            })
        }
        if (searchText.length) {
            filteredTrucks = filteredTrucks.filter(truck => {
                return Object.values(truck).some(value => value?.toString().toLowerCase().includes(searchText.toLowerCase()))
            })
        }

        // Sort by opsDate in descending order
        filteredTrucks.sort((a, b) => new Date(b.opsDate) - new Date(a.opsDate));

        return filteredTrucks;

    }, [station, dateRange, originalPostedTrucks, searchText])

    if (error) {
        return <Empty style={{marginTop: "20vh"}}/>
    }

    if (!data) {
        return <Spin/>;
    }

    return (<>
        <Table
            columns={columns}
            style={{ padding: '24px', background: '#fff' }}
            dataSource={filteredPostedTrucks}
            scroll={{ x: 1300 }}
        />
        <TraboxDrawer
            drawerOpen={showTraboxDrawer}
            setDrawerOpen={setShowTraboxDrawer}
            posting={detail}
            refetch={refetch}
            history={history}
        />

    </>)
};

export default PostingTable;
