import React, { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { CREATE_REQUEST, CREATE_ROUTES_FORECAST, UPDATE_REQUEST, CREATE_TRUCK_POSTING_COMMENT, UPDATE_TRUCK_POSTING_COMMENT, DELETE_TRUCK_POSTING_COMMENT } from '../../../mutations';
import { GET_LABELS, GET_USERS, GET_ROUTES_FORECASTS } from '../../../queries';
import { authenticationService } from '../../../Utilities/authenticationService';
import { DatePickerWithTimeInForm } from '../../../Utilities/datepickerWithTimeInForm';
import {
    Tabs,
    message,
    Select,
    Form,
    Input,
    Button,
    Tooltip,
    Drawer,
    InputNumber,
    Typography,
    Comment,
    Avatar
} from 'antd';
import { CloseOutlined, MessageOutlined } from '@ant-design/icons';
import moment from 'moment';

import { CommentList } from '../../FreightPosting/DetailDrawer';

const TruckSearchRequestDrawer = ({ stationsData, truck, dateRange, posting = {}, paramsVehicleId, showNewTab = true, showExistingTab = true, refetchPostings, disableToId=false, buttonMode, traboxButton }) => {
    const currentUser = authenticationService.currentUserValue

    const searchedStartTime = useMemo(() => moment(dateRange[0]), [dateRange])
    const searchedEndTime = useMemo(() => moment(dateRange[1]), [dateRange])
    const [stationId, setStationId] = useState(currentUser?.station_id);
    const [autoUpdated, setAutoUpdated] = useState(false);
    const { data: usersData } = useQuery(GET_USERS);
    const [labelId, setLabelId] = useState();
    const [toId, setToId] = useState(truck.stationId);
    const [userId, setUserId] = useState();
    const [parkedLoad, setParkedLoad] = useState();
    const [remarks, setRemarks] = useState();
    const [price, setPrice] = useState();
    const [newCommentText, setNewCommentText] = useState('');
    
    const [commentForm] = Form.useForm();
    
    const [getLabelsData, { data: labelsData }] = useLazyQuery(GET_LABELS);
    const [getRoutesForecastData, { data: routeForecastData }] = useLazyQuery(
        GET_ROUTES_FORECASTS, {
            onCompleted: (data) => {
                const vals = form.getFieldsValue(true)
                const route_val_json = JSON.stringify(data.routesForecasts.edges.find(edge => edge.node.vehicleId === paramsVehicleId)?.node)
                form.setFieldsValue({...vals, routeForecast: route_val_json})
            },
            onError: (error) => { console.log(error); }
        }
    );

    
    const [updateRequest] = useMutation(UPDATE_REQUEST, {
        onCompleted: (data) => {
            if (data.updateRequest.request?.overlaps?.length > 0) {
                message.error('選択したトラックは、リクエストされた時間帯にすでにスケジュールされています。リクエストを承認できません。');
            } else {
                message.success(autoUpdated ? 'リクエストを自動承認しました。' : 'リクエストが更新されました')
                refetchPostings()
            }
            setAutoUpdated(false)
        },
        onError: (error) => { console.log(error); }
    });

    const [createRequest] = useMutation(CREATE_REQUEST, {
        onCompleted: (data) => {
            message.success('リクエストを送信しました。');
            setVisible(false);
            if(parseInt(truck.stationId) === currentUser.station_id && parseInt(toId) === currentUser.station_id && parseInt(userId) === currentUser.id) {
                setAutoUpdated(true);
                updateRequest({ variables: { input: { id: data.createRequest.request.id, status: 'accepted' }}})
            }
        },
        onError: (error) => {
            message.error('リクエストを送信できませんでした。');
        }
    });

    const [createRoutesForecast] = useMutation(CREATE_ROUTES_FORECAST, {
        onCompleted: (data) => {
            createRequest({ variables: { input: {
                opsDate: data.createRoutesForecast.routesForecast.opsDate,
                fromId: stationId || currentUser?.station_id,
                forecastId: data.createRoutesForecast.routesForecast.id,
                userId,
                remarks,
                toId,
                status: 'sent',
                truckTableId: truck.id,
                postingId: posting?.id,
                price
            }}});
        },
        onError: (error) => {
            if (error?.networkError?.result?.errors[0]?.message?.includes('overlap')) {
                message.error('スケジュールが重複しています。')
            } else {
                message.error('リクエストを送信できませんでした。');
            }
        }

    });

    const [createTruckPostingComment] = useMutation(CREATE_TRUCK_POSTING_COMMENT, {
        onCompleted: (data) => {
            commentForm.resetFields();
            setNewCommentText('')
            refetchPostings();
        },
        onError: (error) => {
            console.log(error);
        }
    });
    
    const [updateTruckPostingComment] = useMutation(UPDATE_TRUCK_POSTING_COMMENT, {
        onCompleted: (data) => {
            refetchPostings();
        },
        onError: (error) => {
            console.log(error);
        }
    });
    
    const [deleteTruckPostingComment] = useMutation(DELETE_TRUCK_POSTING_COMMENT, {
        onCompleted: (data) => {
            refetchPostings();
        },
        onError: (error) => {
            console.log(error);
        }
    });
    
    const onExistingFinish = ({ remarks, userId, fromId, toId, routeForecast, price }) => {
        setToId(toId);
        setUserId(userId)
        createRequest({ variables: { input: {
            opsDate: JSON.parse(routeForecast).opsDate,
            fromId: fromId,
            forecastId: JSON.parse(routeForecast).id,
            userId,
            remarks,
            toId,
            status: 'sent',
            truckTableId: truck.id,
            price
        }}});
    };

    const onNewFinish = ({ remarks, userId, toId, fromId, departureTime, arrivalTime, price }) => {
        setToId(toId);
        setUserId(userId);
        setRemarks(remarks);
        setPrice(price)
        createRoutesForecast({ variables: { input: {
            opsDate: moment(departureTime).format('YYYY-MM-DD'),
            stationId: fromId,
            vehicleStationId: truck.stationId,
            vehicleId: truck.vehicleId,
            maxTruckCapacity: truck.maxTruckCapacity,
            driverLicenseClass: truck.driverLicenseClass,
            vehicleType: truck.vehicleType,
            remarks,
            labelId,
            parkedLoad,
            departureTime,
            arrivalTime,
            pending: true,
            postingId: posting?.id
        }}});
    };

    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({});
    }, []);

    useEffect(() => {
        if (visible && stationId) {
            const searchString =
                paramsVehicleId === "NoTruck"
                    ? `most_recent_forecast = true AND
                             vehicle_id = NoTruck AND
                             departure_time = "${searchedStartTime}" AND
                             arrival_time = "${searchedEndTime}" AND
                             station_id = ${currentUser?.station_id || stationId}`
                    : `most_recent_forecast = true AND
                             departure_time >= "${searchedStartTime}" AND
                             arrival_time < "${moment(searchedEndTime).add( 1, "minute")}" AND
                             station_id = ${currentUser?.station_id || stationId}`;
            getRoutesForecastData({
                variables: {
                    search: searchString
                }
            });
        }
    }, [getRoutesForecastData, truck, visible, stationId, searchedStartTime, searchedEndTime, paramsVehicleId, currentUser]);

    useEffect(() => {
        getLabelsData({ variables: { stationId: parseInt(stationId) } })
    }, [getLabelsData, visible, stationId]);

    const getForecastSummary = (forecast) => {
        return [forecast.node.label.label, ':',
            moment(forecast.node.departureTime).format('YYYY-MM-DD HH:mm'), 'から',
            moment(forecast.node.arrivalTime).format('YYYY-MM-DD HH:mm'), 'まで'].join('')
    }
    const tailFormItemLayout = {
        wrapperCol: {
            sm: {
                span: 16,
                offset: 9,
            }
        }
    };
    
    const comments = posting.comments
    
    const NewRequestForm = () => {
        return (
        <Form
            name='request'
            form={newForm}
            onFinish={onNewFinish}
            colon={false}
            layout="vertical"
        >
            <Form.Item
                label="依頼元営業所"
                name='fromId'
                initialValue={String(currentUser?.station_id)}
                rules={[{ required: true, message: '営業所を入力してください。' }]}
                
            >
                <Select
                    showSearch
                    placeholder="選択してください"
                    style={{ width: 200 }}
                    disabled={!currentUser?.admin}
                    onChange={(fromId) => setStationId(fromId)}
                >
                    {
                        stationsData.stations.edges.map(
                            station => (
                                <Select.Option key={station.node.id} value={station.node.id}>
                                    {station.node.officialName}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="依頼先営業所"
                name='toId'
                initialValue={truck.stationId}
                rules={[{ required: true, message: '営業所を入力してください。' }]}
            >
                <Select
                    disabled={disableToId}
                    showSearch
                    placeholder="選択してください"
                    style={{ width: 200 }}
                    onChange={
                        selectedId => {
                            setToId(selectedId)
                        }
                    }
                >
                    {
                        stationsData.stations.edges.map(
                            station => (
                                <Select.Option key={station.node.id} value={station.node.id}>
                                    {station.node.officialName}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="承認者"
                name='userId'
                rules={[{ required: true, message: '送り先を入力してください。' }]}
            >
                { usersData && <Select
                    showSearch
                    placeholder="選択してください"
                    style={{ width: 200 }}
                    filterOption={(input,option)=> option.children.join('').includes(input)}
                >
                    {
                        usersData.users.edges.filter(user => user.node.station?.id === toId).map(
                            user => (
                                <Select.Option key={user.node.id} value={user.node.id}>{user.node.lastName} {user.node.firstName}</Select.Option>
                            )
                        )
                    }
                </Select> }
            </Form.Item>
            <Form.Item
                label="ルート"
                name='label'
                rules={[{ required: true, message: 'ルートを入力してください。' }]}
            >
                <Select
                    placeholder="選択してください"
                    showSearch
                    onChange={setLabelId}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        labelsData && labelsData.mostFrequentlyUsedLabels.map(
                            label => (
                                label.enabled &&
                                    <Select.Option key={label.id} value={label.id}>{label.label.join('|')}</Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="運賃"
                name='price'
                rules={[{ required: false, message: '運賃を入力してください。' }]}
            >
                <InputNumber min={0} />
            </Form.Item>
            <DatePickerWithTimeInForm label="出発時刻" name='departureTime' initialTime={dateRange[0]} disableDate={(current) => { return current?.isBefore(searchedStartTime) || current?.isAfter(searchedEndTime) }}/>
            <DatePickerWithTimeInForm label="到着時刻" name='arrivalTime' initialTime={dateRange[1]} disableDate={(current) => { return current?.isBefore(searchedStartTime) || current?.isAfter(searchedEndTime) }}/>
            <Form.Item
                label="荷有り"
                name='parkedLoad'
                rules={[{ required: true, message: '荷物の有無を入力してください。' }]}
            >
                <Select
                    placeholder="選択してください"　onChange={setParkedLoad}>
                    {
                        ["空", "荷あり"].map(parkedLoadType => (
                                <Select.Option key={parkedLoadType} value={parkedLoadType}>
                                    {parkedLoadType}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>

            <Form.Item
                label="備考欄"
                name='remarks'
                rules={[{ required: false }]}
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item shouldUpdate {...tailFormItemLayout}>
                { () => (
                    <Button
                        disabled={
                            stationId === undefined ||
                            labelId === undefined ||
                            parkedLoad === undefined
                        }
                        type="primary"
                        htmlType='submit'
                    >
                        送信する
                    </Button>
                )}
            </Form.Item>
        </Form>
    )}
    const ExistingRequestForm = () => {
        return (
        <Form
            name='request'
            form={form}
            colon={false}
            layout="vertical"
            onFinish={onExistingFinish}
        >
            <Form.Item
                label="依頼元営業所"
                name='fromId'
                initialValue={String(currentUser?.station_id)}
                rules={[{ required: true, message: '営業所を入力してください。' }]}
            >
                <Select
                    showSearch
                    disabled={!currentUser?.admin}
                    onChange={stationId => setStationId(stationId)}
                    placeholder="選択してください"
                    style={{ width: 200 }}
                >
                    {
                        stationsData.stations.edges.map(
                            station => (
                                <Select.Option key={station.node.id} value={station.node.id}>{station.node.officialName}</Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="依頼先営業所"
                name='toId'
                initialValue={truck.stationId}
                rules={[{ required: true, message: '営業所を入力してください。' }]}
            >
                <Select
                    showSearch
                    disabled
                    onChange={stationId => setToId(stationId)}
                    placeholder="選択してください"
                    style={{ width: 200 }}
                >
                    {
                        stationsData.stations.edges.map(
                            station => (
                                <Select.Option key={station.node.id} value={station.node.id}>{station.node.officialName}</Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label="承認者"
                name='userId'
                rules={[{ required: true, message: '送り先を入力してください。' }]}
            >
                { usersData && <Select
                    showSearch
                    placeholder="選択してください"
                    style={{ width: 200 }}
                    filterOption={(input,option)=> option.children.join('').includes(input)}
                >
                    {
                        usersData.users.edges.filter(user => user.node.station?.id === toId).map(
                            user => (
                                <Select.Option key={user.node.id} value={user.node.id}>{user.node.lastName} {user.node.firstName}</Select.Option>
                            )
                        )
                    }
                </Select> }
            </Form.Item>
            { routeForecastData && <Form.Item
                label="ルート"
                name='routeForecast'
                rules={[{ required: true, message: 'ルートを入力してください。' }]}
            >
                <Select
                    showSearch
                    placeholder="選択してください"
                    data-testid='routeForecastFormItem'
                >
                    {
                        routeForecastData && routeForecastData.routesForecasts.edges.map(
                            forecast => (
                                <Select.Option key={forecast.node.id} value={JSON.stringify(forecast.node)}>
                                    <Tooltip placement='top' title={getForecastSummary(forecast)}>
                                        {getForecastSummary(forecast)}
                                    </Tooltip>
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>}
            <Form.Item
                label="運賃"
                name='price'
                rules={[{ required: false, message: '運賃を入力してください。' }]}
            >
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item
                label="備考欄"
                name='remarks'
                rules={[{ required: false }]}
            >
                <Input.TextArea />
            </Form.Item>
            <Form.Item shouldUpdate {...tailFormItemLayout}>
                { () => (
                    <Button
                        disabled={ !form.getFieldValue('toId') ||
                            !form.getFieldValue('userId') ||
                            !form.getFieldValue('routeForecast') ||
                            form.getFieldsError().filter(({ errors }) => errors.length).length}
                        type="primary"
                        htmlType='submit'
                    >
                        送信する
                    </Button>
                )}
            </Form.Item>
        </Form>
    )}
    
    return <>
        {buttonMode ? <Button icon={<MessageOutlined/>} onClick={() => setVisible(true)}/> : <Button type='link' onClick={() => setVisible(true)}>リクエスト</Button>}
        <Drawer
            width={400}
            open={visible}
            footer={null}
            closable={false}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    width: '100%',
                    marginBottom: '-20px'
                }}
            >
                <Button
                    type='text'
                    onClick={() => setVisible(false)}
                    style={{ zIndex: 1 }}
                >
                    <CloseOutlined />
                </Button>
            </div>

            <Tabs items={[
                showNewTab && {
                    label: '新規リクエスト',
                    key: 'new',
                    children: (<NewRequestForm />)
                },
                showExistingTab && {
                    label: '転送リクエスト',
                    key: 'existing',
                    children: (<ExistingRequestForm />)
                }
            ]}>
            </Tabs>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                {traboxButton || null}
            </div>
            <div style={{ width: "100%" }}>
                    {comments?.length ? (
                        <CommentList comments={comments} posting={posting} currentUserId={currentUser.id} deleteComment={deleteTruckPostingComment} updateComment={updateTruckPostingComment}/>
                    ) : (
                        <Typography>コメントがありません。</Typography>
                    )}

                    <Form form={commentForm} onFinish={() => {
                        createTruckPostingComment({
                            variables: {
                                input: {
                                    postingId: posting.id,
                                    commentText: newCommentText,
                                }
                            }
                        
                        })
                    }}>
                        <Comment
                            avatar={<Avatar>{currentUser.last_name[0]}</Avatar>}
                            content={
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Input.TextArea
                                            placeholder="コメントを入力してください。"
                                            autoSize={{ minRows: 5, maxRows: 20 }}
                                            disabled={posting.status === "archived"}
                                            onChange={(e) => setNewCommentText(e.target.value)}
                                        />
                                    
                                    <Button
                                        style={{ height: "auto" }}
                                        disabled={posting.status === "archived" || newCommentText.length < 1}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        送信
                                    </Button>
                                </div>
                            }
                        />
                    </Form>
                </div>
                
        </Drawer>
    </>
};

export default TruckSearchRequestDrawer;
