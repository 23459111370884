import { toFullWidthKatakana, toHalfWidthKatakana } from './katakanaWidthConversion';

export const filterRoutesByDowAndSearchText = (rows, dow, searchText) => {
    let filteredRows = rows

    if (dow || dow === 0) {
        filteredRows = filteredRows.filter(row => {
            return row.opsDow === dow;
        })
    } 

    if (searchText && searchText !== '') {
        filteredRows = filteredRows.filter(row => {
            return row.label.toLowerCase().includes(searchText.toLowerCase())
                || toFullWidthKatakana(row.label.toLowerCase()).includes(searchText.toLowerCase())
                    || toHalfWidthKatakana(row.label.toLowerCase()).includes(searchText.toLowerCase());
        });
    }

    return filteredRows;
}

export const filterRequestsByTabAndSearchText = (rows, tabFilter, searchText) => {
    let filteredRows = rows;

    if (tabFilter && tabFilter !== '') {
        if (tabFilter === 'old') {
            filteredRows = filteredRows.filter(row => row.old);
        } else {
            filteredRows = filteredRows.filter(row => {
                return !row.old && row.status === tabFilter;
            })
        }
    }

    if (searchText && searchText !== '') {
        filteredRows = filteredRows.filter(row => {
            const labelString = row.label?.length > 0 ? row.label.join('|') : '';
            const sender = row.sender?.lastName + row.sender?.firstName
            const receiver = row.approver?.lastName + row.approver?.firstName
            const searchTarget = `${row.to}|${row.vehicleId}|${row.vehicleType}|${labelString}|${sender}|${receiver}|${row.remarks}`

            return searchTarget.toLowerCase().includes(searchText.toLowerCase())
                || toFullWidthKatakana(searchTarget.toLowerCase()).includes(searchText.toLowerCase())
                    || toHalfWidthKatakana(searchTarget.toLowerCase()).includes(searchText.toLowerCase());
        });
    } 

    return filteredRows
}
