import React, { useState } from "react";
import { Space, Card, Tabs, Divider, Form, Row, Col, Select, DatePicker, Button, Spin, Input } from "antd";
import PostingTable from "./PostingTable/PostingTable";
import { useQuery } from "@apollo/client";
import { GET_STATIONS } from "../../queries";
import moment from "moment";
import { useParams } from 'react-router-dom';
import { localeInDatepicker } from "../../Utilities/datepickerWithTimeInForm";

const TruckPosting = () => {
    const { fromDate, toDate, station: paramsStation } = useParams()
    const { data: stationsData, loading: loadingStations } = useQuery(GET_STATIONS);
    const [stations, setStations] = useState(paramsStation ? [paramsStation] : []);
    const [dateRange, setDateRange] = useState((fromDate && toDate && [moment(fromDate), moment(toDate)]) || []);
    const [searchText, setSearchText] = useState('');   
    const [refresh, setRefresh] = useState(false);
    const [form] = Form.useForm();

    const handleDisabledDate = current => {
        return current <= moment().startOf('day') || current > moment().add(5, 'days')
    };

    const SearchErea = () => {
        return <Space size={24} direction="vertical" style={{ width: "100%" }}>
            <Card bordered={false}>
                <Form
                    form={form}
                    layout='inline'
                    size='large'
                    onFinish={
                        ({ dateRange, station, searchText }) => {
                            setStations(station.find(e => e === 'all') ? stationsData.stations.edges.map(e => e.node.id) : station);
                            setDateRange(dateRange);
                            setSearchText(searchText);
                        }
                    }
                >
                    <Row gutter={[16, 8]}>
                        <Col>
                            <Form.Item name='station' label="営業所" initialValue={stations || []}>
                                <Select
                                    showSearch
                                    defaultValue={stations || []}
                                    mode="multiple"
                                    placeholder="選択してください"
                                    style={{ width: 200 }}
                                >
                                    {
                                        stationsData?.stations?.edges.map(station => (
                                            <Select.Option key={station.node.id} value={station.node.id}>
                                                {station.node.officialName}
                                            </Select.Option>
                                        )).concat([<Select.Option key='all' value='all'>全ての営業所</Select.Option>])
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name='dateRange' label="表示期間" initialValue={dateRange || []}>
                                <DatePicker.RangePicker
                                    locale={localeInDatepicker}
                                    disabledDate={handleDisabledDate}
                                    defaultValue={dateRange || []}
                                    showTime={{defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')]}}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="searchText" initialValue={searchText}>
                                <Input.Search placeholder="例：冷蔵車、パン"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item shouldUpdate>
                                {
                                    () => (
                                        <>
                                            <Button
                                                disabled={
                                                    !form.getFieldValue('station') ||
                                                    !form.getFieldValue('dateRange')
                                                }
                                                htmlType="submit" type='primary'>
                                                検索
                                            </Button>
                                        </>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Space>
    }
    const items = [
        {
            key: 'postingTable',
            label: '進行中',
            children: (
                <PostingTable
                    dateRange={dateRange}
                    station={stations}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    searchText={searchText}
                />
            )
        },
        {
            key: 'previous', // todo: filter by postings that have expired
            label: '過去の投稿',
            children: (
                <PostingTable
                    dateRange={dateRange}
                    station={stations}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    searchText={searchText}
                    onlyExpired={true}
                />
            )
        },
    ];

    if (loadingStations) {
        return <Spin/>;
    }

    return <>
        <SearchErea />
        <Divider style={{ margin: 0, padding: 0 }}/>
        <Card bordered={false} style={{ marginTop: '1.5rem' }}>
            <Tabs items={items} tabBarStyle={{ marginBottom: 'auto', borderRadius: '5px 5px 0 0'}} type="card" defaultActiveKey='truckSearch' />
        </Card>
    
    </>;
    
}
export default TruckPosting;
