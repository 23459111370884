export const PATHS = {
    login: '/login',
    resetPassword: '/reset_password/:token?',
    forgotPassword: '/forgot_password',
    userSettings: '/userSettings',
    labeling: '/labeling',
    dashboard: '/dashboard',
    truckSchedule: '/truckSchedule',
    truckStats: '/truckStats/:referenceMonth?/:dowFilter?/:station?/:threshold?',
    routeManagement: '/routeManagement',
    requestsList: '/requestsList/:tabName?/:requestId?',
    truckPosting: '/truckPosting/:fromDate?/:toDate?/:station?/:vehicleId?',
    tutorial: '/tutorial',
    askQuestion: '/askQuestion',
    freightPosting: '/freightPosting/:postingId?',
    archived: '/archived',
    simulation: '/simulation',
};

export const DENTIST_API_ROOT = process.env.REACT_APP_DENTIST_API_ROOT;
