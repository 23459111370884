import React  from 'react';
import { Layout, Tabs, Card, Space } from 'antd';
import { UserOutlined, TeamOutlined, SettingOutlined, IdcardOutlined } from '@ant-design/icons';
import AccountInformation from './AccountInformation';
import UserManagement from './UserManagement';
import CompanyManagement from './CompanyManagement';

const { Content } = Layout;

const UserSettings = () => {
    const items = [
        {
            key: 'accountInformation',
            label: <><UserOutlined/>アカウント</>,
            icon: <SettingOutlined />,
            children: <AccountInformation/>
        },
        JSON.parse(localStorage.currentUser).admin && {
            key: 'userManagement',
            label: <><TeamOutlined/>ユーザー管理</>,
            icon: <TeamOutlined />,
            children: <UserManagement/>
        },
        JSON.parse(localStorage.currentUser).admin && {
            key: 'companyManagement',
            label: <><IdcardOutlined />会社管理</>,
            icon: <IdcardOutlined />,
            children: <CompanyManagement/>
        }
    ];

    return <Layout>
        <Space direction='vertical' style={{ width: '100%'}}>
        <Card bordered={false}>
        <Content className='site-layout-background'>
            <Tabs tabPosition='left' defaultActiveKey={'accountInformation'} items={items} />
        </Content>
        </Card>
        </Space>
    </Layout>;
};

export default UserSettings;
